export const skills = [
  {
    name: "JAVA",
    position: [12, 6.5, 24],
    description: [
      "This is my best programming language, i use it for most of my projects",
    ],
    proficiency: 5,
  },
  {
    name: "JAVASCRIPT",
    position: [12, 1, 20],
    description: ["The language I use to write ReactJS, Vue3JS, and ExpressJS"],
    proficiency: 5,
  },
  {
    name: "VUE3",
    position: [-40, -12, 0],
    description: [
      "Has experience with commercial applications",
      "Vuex",
      "Vuetify",
      "Axios",
      "Vue-Hooks-Form",
      "NuxtJs"
    ],
    proficiency: 5,
  },
  {
    name: "SPRINGBOOT",
    position: [-80, 0, 20],
    description: [
      "Has experience with commercial applications",
      "Spring Data JDBC/JPA/Redis",
      "Spring Security",
      "Spring AOP",
      "Atomikos Transaction Manager",
      "OpenAPI/Swagger",
      "Spring Cloud",
      "Thymeleaf",
      "Spring Batch",
      "Spring Cache",
      "JMS"
    ],
    proficiency: 5,
  },
  {
    name: "REDISDB",
    position: [-90, 16, -12],
    description: [
      "Has experience with commercial applications",
      "Able to set up Redis Server from sketch and implement to actual usage",
    ],
    proficiency: 5,
  },
  {
    name: "CPP",
    position: [-120, 30, 8],
    description: ["Familier with C++", "Able to maintain memory usage"],
    proficiency: 5,
  },
  {
    name: "PLAYWRIGHT",
    position: [-75, -30, 1.3],
    description: [
      "Has experience with commercial applications",
      "Able to login to a website, open nested layer and use css selector to crawl require information",
    ],
    proficiency: 5,
  },
  {
    name: "AWS",
    position: [-30, -40, 1],
    description: [
      "ECS",
      "EKS",
      "NLB",
      "ALB",
      "Route53",
      "VPC",
      "KMS",
      "Event Bridge",
      "SQS",
      "SNS",
      "Lambda",
      "S3",
      "Glue",
      "RDS",
      "ETL Pipeline",
    ],
    proficiency: 5,
  },
  {
    name: "Kubernetes",
    position: [-60, -40, 1],
    description: [
      "Namespace",
      "Egress",
      "Ingress",
      "Deployment",
      "Service",
      "ConfigMap",
      "PV/PVC",
      "NetworkPolicy",
      "HttpProxy",
      "Secret",
      "ResourceQuota"
    ],
    proficiency: 5,
  },
  {
    name: "Terraform",
    position: [100, -30, 1],
    description: [
      "Use Alats module to provision infra for JPMorgan Chase"
    ],
    proficiency: 5,
  },
  {
    name: "Nginx",
    position: [-80, 0, 80],
    description: [
      "This website and 6 more are hosted in Oracle Cloud Ec2 machine"
    ],
    proficiency: 5,
  },
  {
    name: "Jenkins",
    position: [-70, 40, -20],
    description: [
      "Able to use Groovy to define stages"
    ],
    proficiency: 5,
  },
  {
    name: "HTML",
    position: [-60, -40, 1],
    description: [""],
    proficiency: 5,
  },
  {
    name: "CSS",
    position: [-85, -40, -1],
    description: ["Bootstrap", "Simple Animation"],
    proficiency: 5,
  },
  {
    name: "PYTHON",
    position: [10, -25, 2],
    description: [
      "Use for my Image Processing, Computer Vision Course and some leetcode",
      "NumPy",
      "Pandas",
      "Matplotlib",
      "OpenCV",
    ],
    proficiency: 5,
  },
  {
    name: "SQL",
    position: [10, -35, 12],
    description: [
      "Postgresql",
      "Oracle",
      "Has experience with commercial applications",
      "Procedure",
      "Function",
      "Trigger",
      "View",
      "Validation",
      "Permission",
    ],
    proficiency: 5,
  },
  {
    name: "MONGODB",
    position: [30, 50, 8],
    description: ["Use for my personal web projects"],
    proficiency: 5,
  },
  {
    name: "REACT",
    position: [20, 60, 12],
    description: [
      "This web site is build by React",
      "React Hooks",
      "React Hook Form",
      "Redux",
      "Axios",
      "Socket.IO",
      "MUI",
      "ThreeJS",
      "NextJs",
      "React Table",
      "React Query"
    ],
    proficiency: 5,
  },
  {
    name: "NODE",
    position: [40, 20, -8],
    description: ["Use for my web services"],
    proficiency: 5,
  },
  {
    name: "EXPRESS",
    position: [180, 40, -20],
    description: [
      "Use for my Backend services",
      "Passport",
      "Mongoose",
      "Cookie Parser",
      "Socket.IO",
    ],
    proficiency: 5,
  },
  {
    name: "NEXT",
    position: [-90, 30, 1],
    description: ["Use for my web services", "NextAuth.js"],
    proficiency: 5,
  },
  {
    name: "DOCKER",
    position: [60, -60, 8],
    description: [
      "This website running in a docker container and hosted in Oracle Cloud",
      "I build a modified nginx image and hosted on Docker Hub for this website",
      "Able to set up container environment and build matched images for different applications",
    ],
    proficiency: 5,
  },
  {
    name: "GCP",
    position: [-60, 60, 4],
    description: [
      "Google is willing to give us $300 for a year, so i was playing with it",
      "Oauth2 and Playground",
      "Firebase",
      "Gmail API",
      "But my free trial ends, i give it up and buy a Raspberry Pi and made my own server",
    ],
    proficiency: 5,
  },
  {
    name: "BASH",
    position: [90, -60, 0.4],
    description: [
      "During my work, I need to check which port is available, send email if a service goes down and restart automatically",
    ],
    proficiency: 5,
  },
  {
    name: "TAILWINDCSS",
    position: [-120, -80, -1],
    description: ["Bootstrap alternatives"],
    proficiency: 5,
  },
];
