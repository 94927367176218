import React, { useState } from "react";
import { ReactTerminal } from "react-terminal";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { themeActions, navActions } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { lightTheme } from "../../components/theme/muiTheme";
import { darkTheme } from "../../components/theme/muiTheme";

export default function Terminal() {
  let navigate = useNavigate();
  const ipAddress = useSelector((state) => state.nav.ipAddress);
  const fontColor = useSelector((state) => state.theme);
  const [theme, setTheme] = useState(fontColor.terminal.currentTheme);

  const { changeValue, showButtonNav, showTerminalNav } = bindActionCreators(
    navActions,
    useDispatch()
  );
  const { changeTheme } = bindActionCreators(themeActions, useDispatch());

  const routeChange = (path) => {
    navigate(path);
  };

  const commands = {
    cd: (path) => {
      switch (path) {
        case "home":
        case "~":
          routeChange("/");
          changeValue("/home");
          return "Here we are at " + path + " Page.";
        case "skill":
          routeChange("/skill");
          break;
        case "experience":
          routeChange("/experience");
          break;
        case "contact":
          routeChange("/contact");
          break;
        case "car-nav":
          routeChange("/welcome");
          break;
        default:
          return cdErrorMessage;
      }
      changeValue("/" + path);
      return "Here we are at " + path + " Page.";
    },
    theme: (value) => {
      switch (value) {
        case "light":
          changeTheme(lightTheme);
          setTheme(value);
          return (
            <span
              style={{
                color: fontColor.terminal.normalColor,
                marginLeft: "2vw",
              }}
            >
              Changed to <strong>{value}</strong>
            </span>
          );

        case "dark":
          changeTheme(darkTheme);
          setTheme(value);
          return (
            <span
              style={{
                color: fontColor.terminal.normalColor,
                marginLeft: "2vw",
              }}
            >
              Changed to <strong>{value}</strong>
            </span>
          );
        default:
          return themeErrorMessage;
      }
    },
    help: () => {
      return helpMessage;
    },
    mode: (mode) => {
      switch (mode) {
        case "normal":
          showButtonNav(true);
          showTerminalNav(false);
          break;

        case "terminal":
          showButtonNav(false);
          showTerminalNav(true);
          break;
        default:
          return modeErrorMessage;
      }

      return "";
    },
  };

  const helpMessage = (
    <span style={{ color: fontColor.terminal.normalColor }}>
      <br />
      <span style={{ marginLeft: "2vw" }}>
        <strong>cd</strong>: [argument]
      </span>
      <br />

      <span style={{ marginLeft: "3vw" }}>
        home &nbsp; &nbsp; skill &nbsp; &nbsp; experience &nbsp; &nbsp; contact
        &nbsp; &nbsp; car-nav
      </span>
      <br />
      <br />
      <span style={{ marginLeft: "2vw" }}>
        <strong>theme</strong>: [argument]
      </span>
      <br />

      <span style={{ marginLeft: "3vw" }}>light &nbsp; &nbsp; dark</span>
      <br />
      <br />
      <span style={{ marginLeft: "2vw" }}>
        <strong>mode</strong>: [argument]
      </span>
      <br />

      <span style={{ marginLeft: "3vw" }}>normal &nbsp; &nbsp; terminal</span>
      <br />
    </span>
  );

  const themeErrorMessage = (
    <span style={{ color: fontColor.terminal.errorColor }}>
      <br />
      <span style={{ marginLeft: "2vw" }}>
        Please enter theme <strong>space</strong> and then follow by either one
        of following
      </span>
      <br />
      <span style={{ marginLeft: "3vw" }}>light &nbsp; &nbsp; dark</span>
      <br />
    </span>
  );

  const modeErrorMessage = (
    <span style={{ color: fontColor.terminal.errorColor }}>
      <br />
      <span style={{ marginLeft: "2vw" }}>
        Please enter mode <strong>space</strong> and then follow by either one
        of following
      </span>
      <br />
      <span style={{ marginLeft: "3vw" }}>normal &nbsp; &nbsp; terminal</span>
      <br />
    </span>
  );

  const cdErrorMessage = (
    <span style={{ color: fontColor.terminal.errorColor }}>
      <br />
      <span style={{ marginLeft: "2vw" }}>
        Please enter cd <strong>space</strong> and then follow by either one of
        following
      </span>
      <br />
      <span style={{ marginLeft: "3vw" }}>
        home &nbsp; &nbsp; skill &nbsp; &nbsp; experience &nbsp; &nbsp; contact
        &nbsp; &nbsp; car-nav
      </span>
      <br />
    </span>
  );

  const welcomeMessage = (
    <span style={{ color: fontColor.terminal.normalColor }}>
      <span style={{ marginLeft: "2vw" }}>
        Type <strong>help</strong> for command list
      </span>
      <br />
      <br />
      <span style={{ marginLeft: "2vw" }}>
        Press <strong>c</strong> key at anytime to summon the command window
      </span>
      <br />
      <br />
      <span style={{ marginLeft: "2vw" }}>
        Or <strong>esc</strong> key to temporarily exit
      </span>
      <br />
      <br />
      <span style={{ marginLeft: "2vw" }}>
        Or type <strong>mode normal</strong> back to normal mode
      </span>
      <br />
      <br />
    </span>
  );

  const commandErrorMessage = () => {
    return (
      <span style={{ color: fontColor.terminal.errorColor, marginLeft: "1vw" }}>
        Command not found
      </span>
    );
  };

  return (
    <div
      style={{
        overflow: "hidden",
        height: "40vh",
        maxHeight: "100vh",
        width: "100vw",
        maxWidth: "100vw",
      }}
    >
      <ReactTerminal
        showControlBar={false}
        welcomeMessage={welcomeMessage}
        commands={commands}
        prompt={ipAddress + "@longlong:"}
        errorMessage={() => commandErrorMessage()}
        theme={theme}
      />
    </div>
  );
}
