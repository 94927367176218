import React from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import profile from "../../assets/images/longlong.jpg";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CodeIcon from "@mui/icons-material/Code";
import CustomizedTimeline from "../../components/timeline/Timeline";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { Scrollbars } from "react-custom-scrollbars";

export default function Experience() {
  const theme = useSelector((state) => state.theme);

  const downloadResume = () => {
    var element = document.getElementById("downloadResume");
    element.click();
  };

  return (
    <div>
      <Scrollbars
        style={{
          backgroundColor: theme.home.backgroundColor,
          overflowX: "hidden",
          height: "84vh",
          width: "90%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "6vh",
          borderRadius: "10px",
          boxShadow:
            "0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%)",
        }}
      >
        <Box>
          <Grid container component="main">
            <CssBaseline />
            <Grid item xs={12} sm={12} md={6}>
              <div style={{ marginTop: "8vh", textAlign: "center" }}>
                <Tooltip title="Longlong Lu" placement="right-start">
                  <img
                    alt=""
                    src={profile}
                    style={{
                      width: "150px",
                      height: "150px",
                      borderRadius: "50%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                </Tooltip>
                <h6
                  style={{
                    color: "#F5EBE7",
                    fontFamily: "Ephesis",
                    marginTop: "3vh",
                  }}
                >
                  Longlong Lu
                </h6>

                <div style={{ marginTop: "3vh" }}>
                  <Tooltip title="Github" placement="left">
                    <IconButton
                      style={{ marginTop: "-5vh" }}
                      onClick={() => {
                        window.open("https://github.com/longlonglu");
                      }}
                    >
                      <GitHubIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="LinkedIn" placement="top">
                    <IconButton
                      style={{ marginTop: "-5vh" }}
                      onClick={() => {
                        window.open(
                          "https://www.linkedin.com/in/longlong-lu-360471201/"
                        );
                      }}
                    >
                      <LinkedInIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="LeetCode" placement="top">
                    <IconButton
                      style={{ marginTop: "-5vh" }}
                      onClick={() => {
                        window.open("https://leetcode.com/longlylonely/");
                      }}
                    >
                      <CodeIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Download My Resume" placement="right">
                    <IconButton
                      style={{ marginTop: "-5vh" }}
                      onClick={() => {
                        downloadResume();
                      }}
                    >
                      <DocumentScannerIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>

              <p
                style={{
                  marginLeft: "4vw",
                  marginTop: "3vh",
                  color: theme.experience.descriptionFontColor,
                  fontFamily: "Ephesis",
                }}
              >
                {"<body>"}
              </p>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                style={{
                  marginLeft: "6vw",
                  marginRight: "5vw",
                  borderRadius: "10px",
                  boxShadow: 10,
                }}
              >
                <Typography
                  style={{
                    color: theme.experience.titleFontColor,
                    fontFamily: "Sans-Serif",
                    fontSize: "1.3rem",
                    lineHeight: "1.3",
                  }}
                >
                  I'm happy to be a programer. Passionate about creating new
                  applications that makes my life easier like ChatBot, StockBot,
                  NAS, AdGuard and so on. Even though i spend a lot of time on
                  Stack Overflow(haha).
                </Typography>
                <br />
                <br />
                <Typography
                  style={{
                    color: theme.experience.titleFontColor,
                    fontFamily: "Sans-Serif",
                    fontSize: "1.3rem",
                    lineHeight: "1.3",
                  }}
                >
                  Highly innovative with strong analytical and entrepreneurial skills, adept at deriving new
ideas from existing systems and data. Highly adaptable and a continual self-learner.

                </Typography>
              </Grid>
              <p
                style={{
                  marginLeft: "4vw",
                  color: theme.experience.descriptionFontColor,
                  fontFamily: "Ephesis",
                }}
              >
                {"</body>"}
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} style={{ marginTop: "5vh" }}>
              <CustomizedTimeline />
            </Grid>
          </Grid>
        </Box>
        <a id="downloadResume" href="/Longlong_Lu.pdf" download></a>
      </Scrollbars>
    </div>
  );
}
