import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { useSelector } from "react-redux";

export default function CustomizedTimeline() {
  const theme = useSelector((state) => state.theme);

  return (
    <VerticalTimeline>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{
          background: theme.timeLine.right.contentBackground,
          color: theme.timeLine.right.contentFontColor,
        }}
        contentArrowStyle={{
          borderRight: theme.timeLine.right.contentArrowStyle,
        }}
        date="Feb 2023 - Present"
        iconStyle={{
          background: theme.timeLine.right.iconBackground,
          color: theme.timeLine.right.iconColor,
        }}
      >
        <h3 className="vertical-timeline-element-title">JPMorgan Chase</h3>
        <h4 className="vertical-timeline-element-subtitle">
          &nbsp; Full Stack Engineer
        </h4>
        <ol class="list-disc">
          <li>
            <p style={{ lineHeight: "1.1" }}>
              Led a group of interns to modernize an outdated application,
              providing development guidance, mentorship, and support throughout
              the project
            </p>
          </li>
          <li>
            <p style={{ lineHeight: "1.1" }}>
              Delivered a near real-time GPS application for the New York City
              Sanitation Department to perform trash truck route tracking and
              allocation
            </p>
          </li>
          <li>
            <p style={{ lineHeight: "1.1" }}>
              Set up the CI/CD pipeline using Jenkins with Bitbucket,
              incorporating component/contract testing, Sonar code scanning,
              SSAP security scanning, and Skaffold for building and uploading
              Docker images. Implemented automatic certificate renewal,
              Kubernetes, FluentD with Splunk, Dynatrace, and Grafana for
              application deployment and monitoring. This setup allowed the team
              to focus solely on code quality
            </p>
          </li>
          <li>
            <p style={{ lineHeight: "1.1" }}>
              Working closely with various application owners to gather data,
              share experiences and build reports based on business requirements
            </p>
          </li>
          <li>
            <p style={{ lineHeight: "1.1" }}>
              Using AWS ETL pipeline to process over 200 million records,
              transferring, processing, and generating reports nightly
            </p>
          </li>
          <li>
            <p style={{ lineHeight: "1.1" }}>
              Delivered application that tracks the usage of each financial
              product, providing insights for more informed marketing decisions
            </p>
          </li>
          <li>
            <p style={{ lineHeight: "1.1" }}>
              Delivered application to view detailed information and performance
              metrics of bankers, enhancing human resource management.
            </p>
          </li>
        </ol>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{
          background: theme.timeLine.left.contentBackground,
          color: theme.timeLine.left.contentFontColor,
        }}
        contentArrowStyle={{
          borderRight: theme.timeLine.left.contentArrowStyle,
        }}
        date="Nov 2021 - Oct 2022"
        iconStyle={{
          background: theme.timeLine.left.iconBackground,
          color: theme.timeLine.left.iconColor,
        }}
      >
        <h3 className="vertical-timeline-element-title">
          Forward Thinking System
        </h3>
        <h4 className="vertical-timeline-element-subtitle">
          &nbsp; Full Stack Engineer
        </h4>
        <ol class="list-disc">
          <li>
            <p style={{ lineHeight: "1.1" }}>
              Collaborated with senior consultants, including software
              developers, UX designers, project/product managers, and the audit
              team, in a small agile team to build responsive and scalable
              cross-platform applications
            </p>
          </li>
          <li>
            <p style={{ lineHeight: "1.1" }}>
              Delivered a near real-time GPS application for the New York City
              Sanitation Department to perform trash truck route tracking and
              allocation
            </p>
          </li>
          <li>
            <p style={{ lineHeight: "1.1" }}>
              Delivered a dynamic PDF generator using the Thymeleaf Template
              Engine, enabling real-time template uploads, format changes, and
              data modifications
            </p>
          </li>
          <li>
            <p style={{ lineHeight: "1.1" }}>
              Delivered an API gateway and rate limiter using Spring Boot and
              Redis, supporting customizable limits for each customer and
              mitigating DDoS attacks
            </p>
          </li>
          <li>
            <p style={{ lineHeight: "1.1" }}>
              Migrated the eCRM application from JSP and Jersey to ReactJS and
              Spring Boot
            </p>
          </li>
        </ol>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{
          background: theme.timeLine.right.contentBackground,
          color: theme.timeLine.right.contentFontColor,
        }}
        contentArrowStyle={{
          borderRight: theme.timeLine.right.contentArrowStyle,
        }}
        iconStyle={{
          background: theme.timeLine.right.iconBackground,
          color: theme.timeLine.right.iconColor,
        }}
        date="Apr 2021 - Nov 2021"
      >
        <h3 className="vertical-timeline-element-title">
          Verizon (contractor via Infosys)
        </h3>
        <h4 className="vertical-timeline-element-subtitle">
          &nbsp; Backend Developer
        </h4>

        <ol class="list-disc">
          <li>
            <p style={{ lineHeight: "1.1" }}>
              Maintain, Monitor and Test the system and servers.
            </p>
          </li>
          <li>
            <p style={{ lineHeight: "1.1" }}>
              Write automation testing(Component, E2E, Performace) kits by using
              SmartSpec, Cypress and BlazeMeter
            </p>
          </li>
        </ol>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{
          background: theme.timeLine.left.contentBackground,
          color: theme.timeLine.left.contentFontColor,
        }}
        contentArrowStyle={{
          borderRight: theme.timeLine.left.contentArrowStyle,
        }}
        iconStyle={{
          background: theme.timeLine.left.iconBackground,
          color: theme.timeLine.left.iconColor,
        }}
        date="Mar 2021 - Nov 2021"
      >
        <h3 className="vertical-timeline-element-title">Infosys</h3>
        <h4 className="vertical-timeline-element-subtitle">
          &nbsp; Associate Software Development Engineer
        </h4>

        <ol class="list-disc">
          <li>
            <p style={{ lineHeight: "1.1" }}>
              Trained as junior programmer in programming methodologies and best
              practices.
            </p>
          </li>
          <li>
            <p style={{ lineHeight: "1.1" }}>
              Build a PayPal like application by using Angular, .NET and
              Microsoft SQL Server.
            </p>
          </li>
        </ol>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{
          background: theme.timeLine.right.contentBackground,
          color: theme.timeLine.right.contentFontColor,
        }}
        contentArrowStyle={{
          borderRight: theme.timeLine.right.contentArrowStyle,
        }}
        iconStyle={{
          background: theme.timeLine.right.iconBackground,
          color: theme.timeLine.right.iconColor,
        }}
        date="Aug 2017 - Feb 2021"
      >
        <h3 className="vertical-timeline-element-title">
          Queens College (CUNY)
        </h3>
        <h4 className="vertical-timeline-element-subtitle">
          &nbsp; Computer Science Major
        </h4>
        <ol class="list-disc">
          <li>
            <p style={{ lineHeight: "1.1" }}>
              Dean's List of Queens College 2018-2019 Academic Year
            </p>
          </li>
          <li>
            <p style={{ lineHeight: "1.1" }}>
              iQ4 Cybersecurity Internship Cas Study Certificate of Achievement
            </p>
          </li>
        </ol>
      </VerticalTimelineElement>
    </VerticalTimeline>
  );
}
