import { BOTTOMM_NAVIGATION_VALUE } from "../constants/navConstants";
import { IP_ADDRESS } from "../constants/navConstants";
import { SHOW_BOTTOMM_NAVIGATION } from "../constants/navConstants";
import { SHOW_TERMINAL_NAVIGATION } from "../constants/navConstants";
export const changeValue = (payload) => {
  return (dispatch) => {
    dispatch({ type: BOTTOMM_NAVIGATION_VALUE, payload });
  };
};

export const setIpAddress = (payload) => {
  return (dispatch) => {
    dispatch({ type: IP_ADDRESS, payload });
  };
};

export const showButtonNav = (payload) => {
  return (dispatch) => {
    dispatch({ type: SHOW_BOTTOMM_NAVIGATION, payload });
  };
};

export const showTerminalNav = (payload) => {
  return (dispatch) => {
    dispatch({ type: SHOW_TERMINAL_NAVIGATION, payload });
  };
};
