import {
  ACCOUNT_DEPOSIT,
  ACCOUNT_WITHDRAW,
  ACCOUNT_ERROR,
} from "../constants/accountConstants";

const reducer = (
  state = {
    accountId: 0,
    balance: 0,
    message: "",
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ACCOUNT_DEPOSIT:
      return {
        accountId: action.payload.accountId,
        balance: action.payload.balance,
        message: action.payload.message,
      };
    case ACCOUNT_WITHDRAW:
      return {
        accountId: action.payload.accountId,
        balance: action.payload.balance,
        message: action.payload.message,
      };
    case ACCOUNT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
