import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import JellyFont from "../../components/jellyFont/JellyFont";
import SpringBox from "../../components/models/springBox/SpringBox";
import { Scrollbars } from "react-custom-scrollbars";
import Typography from "@mui/material/Typography";

export default function Contact() {
  const theme = useSelector((state) => state.theme);

  function validateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  }

  const [email, setEmail] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(!validateEmail(email));
  };
  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = () => {
    if (emailError) {
      alert("email is not value");
      return;
    }
    console.log(email);
    console.log(subject);
    console.log(message);
  };

  return (
    <Scrollbars
      style={{
        backgroundColor: theme.contact.backgroundColor,
        overflowX: "hidden",
        height: "84vh",
        width: "90%",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "6vh",
        borderRadius: "10px",
        boxShadow:
          "0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%)",
      }}
    >
      <Box>
        <Grid container component="main" sx={{ height: "84vh" }}>
          <CssBaseline />
          <Grid item xs={12} sm={6} md={6}>
            <SpringBox />
          </Grid>

          <Grid item xs={12} sm={6} md={6} elevation={6} square align="center">
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              style={{ marginTop: "10vh" }}
            >
              <JellyFont
                title={"Contact me"}
                size={"4rem"}
                color={theme.contact.titleFontColor}
                style={{ marginLeft: "-30vw" }}
              />

              <Typography
                style={{
                  color: theme.contact.descriptionFontColor,
                  fontFamily: "Sans-Serif",
                  fontSize: "1.3rem",
                }}
              >
                I’m interested in SDE, especially Backend. Don’t hesitate to
                drop me a message.
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
                style={{ marginTop: "3vh" }}
              >
                <TextField
                  error={emailError}
                  autoComplete="off"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                  helperText={emailError && "Incorrect entry."}
                  variant="outlined"
                />

                <TextField
                  margin="normal"
                  autoComplete="off"
                  required
                  fullWidth
                  id="subject"
                  label="Subject"
                  name="subject"
                  value={subject}
                  onChange={handleSubjectChange}
                />
                <TextField
                  margin="normal"
                  autoComplete="off"
                  required
                  fullWidth
                  name="message"
                  label="Message"
                  type="text"
                  id="message"
                  minRows="10"
                  multiline={true}
                  value={message}
                  onChange={handleMessageChange}
                />
                <Grid container direction="column" item xs={12}>
                  <Grid
                    item
                    container
                    direction="column"
                    alignItems="flex-end"
                    justify="flex-start"
                  >
                    <Button
                      onClick={handleSubmit}
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Send message!
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Scrollbars>
  );
}
