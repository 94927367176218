import React, { Suspense, useRef, useMemo } from "react";
import * as THREE from "three";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { Physics, useCylinder, usePlane, useBox } from "@react-three/cannon";
import { Environment, OrbitControls } from "@react-three/drei";
import Vehicle from "./Vehicle";
import CameraControls from "camera-controls";
CameraControls.install({ THREE });

export default function PlayGround() {
  const carPositionRef = useRef([0, 40, 40]);

  function Controls({ pos = new THREE.Vector3(), look = new THREE.Vector3() }) {
    const camera = useThree((state) => state.camera);
    const gl = useThree((state) => state.gl);
    const controls = useMemo(
      () => new CameraControls(camera, gl.domElement),
      []
    );
    return useFrame((state, delta) => {
      pos.set(
        carPositionRef.current[0],
        carPositionRef.current[1] + 10,
        carPositionRef.current[2] + 3
      );
      look.set(
        carPositionRef.current[0],
        carPositionRef.current[1],
        carPositionRef.current[2] - 3
      );
      state.camera.position.lerp(pos, 0.4);
      // state.camera.updateProjectionMatrix();
      controls.setLookAt(
        state.camera.position.x,
        state.camera.position.y,
        state.camera.position.z,
        look.x,
        look.y,
        look.z,
        true
      );
      return controls.update(delta);
    });
  }
  return (
    <>
      <Canvas shadows>
        <ambientLight intensity={0.1} />
        <spotLight
          position={[0, 10, 40]}
          angle={0.8}
          intensity={5}
          castShadow
          penumbra={1}
        />
        <directionalLight position={[-40, 20, 20]} color="#FFF7AB" />
        <directionalLight
          position={[10.5, 20, 10]}
          intensity={1.5}
          color="#FFF7AB"
        />
        <Physics
          broadphase="SAP"
          contactEquationRelaxation={4}
          friction={1e-3}
          allowSleep
        >
          <Plane rotation={[-Math.PI / 2, 0, 0]} userData={{ id: "floor" }} />
          <Vehicle
            ref={carPositionRef}
            position={[0, 1, 20]}
            rotation={[0, 10, 0]}
            angularVelocity={[0, 1, 0]}
            wheelRadius={0.3}
          />
          <Pillar position={[0, 2.5, -5]} userData={{ id: "pillar-2" }} />
          <WallV position={[40, 0, 0]} />
          <WallV position={[-40, 0, 0]} />

          <WallH position={[0, 0, 25]} />
          <WallH position={[0, 0, -30]} />
        </Physics>
        <Suspense fallback={null}>
          <Environment preset="night" />
        </Suspense>
        {/* <OrbitControls /> */}
        <Controls />
      </Canvas>
    </>
  );
}

function Plane(props) {
  const [ref] = usePlane(() => ({
    type: "Static",
    material: "ground",
    ...props,
  }));
  return (
    <group ref={ref}>
      <mesh receiveShadow>
        <planeGeometry args={[200, 200]} />
        <meshStandardMaterial color="#ffcda3" />
      </mesh>
    </group>
  );
}

function Pillar({ args = [0.7, 0.7, 5, 16], ...props }) {
  const [ref] = useCylinder(() => ({ mass: 1, args, ...props }));
  return (
    <mesh ref={ref} castShadow>
      <cylinderGeometry args={args} />
      <meshNormalMaterial color="#e78f48" />
    </mesh>
  );
}

function WallV({ args = [1, 1, 58], ...props }) {
  const [ref] = useBox(() => ({ mass: 1000, args, ...props }));
  return (
    <mesh ref={ref} castShadow>
      <boxGeometry args={args} />
      <meshStandardMaterial color="#e78f48" />
    </mesh>
  );
}

function WallH({ args = [78, 1, 1], ...props }) {
  const [ref] = useBox(() => ({ mass: 1000, args, ...props }));
  return (
    <mesh ref={ref} castShadow>
      <boxGeometry args={args} />
      <meshStandardMaterial color="#e78f48" />
    </mesh>
  );
}

function Area({ args = [1, 1, 1], ...props }) {
  return (
    <mesh castShadow>
      <boxGeometry args={args} />
      <meshStandardMaterial color="#e78f48" />
    </mesh>
  );
}
