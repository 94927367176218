import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import Home from "./pages/home/Home";
import Skill from "./pages/skill/Skill";
import Experience from "./pages/experience/Experience";
import Contact from "./pages/contact/Contact";
import CarNavBar from "./components/carNavBar/CarNavBar";
import { TerminalContextProvider } from "react-terminal";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { navActions } from "./redux/actions";
import axios from "axios";
import Navigation from "./components/navigation/Navigation";
import Loading from "./pages/loading/Loading";

function App() {
  const theme = useSelector((state) => state.theme);
  const { setIpAddress } = bindActionCreators(navActions, useDispatch());

  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIpAddress(res.data.IPv4);
  };

  useEffect(() => {
    getData();
  }, []);

  const [loading, setLoding] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoding(false);
    }, 2500);
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <TerminalContextProvider>
          <ThemeProvider theme={theme}>
            <Router>
              <Navigation />

              <Routes>
                <Route path="/welcome" element={<CarNavBar />} />

                <Route path="/contact" element={<Contact />} />
                <Route path="/experience" element={<Experience />} />
                <Route path="/skill" element={<Skill />} />
                <Route path="/" element={<Home />} />
              </Routes>
            </Router>
          </ThemeProvider>
        </TerminalContextProvider>
      )}
    </>
  );
}

export default App;
