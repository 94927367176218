import { OPEN_DIALOG, CLOSE_DIALOG } from "../constants/dialogConstants";
import { skills } from "../../pages/skill/skills";

const reducer = (
  state = {
    value: false,
    data: {},
  },
  action
) => {
  switch (action.type) {
    case OPEN_DIALOG:
      var language = skills.find((e) => e.name === action.payload);
      return {
        value: true,
        data: language,
      };
    case CLOSE_DIALOG:
      return {
        value: false,
        data: {},
      };
    default:
      return state;
  }
};

export default reducer;
