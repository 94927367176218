import { useEffect, useRef, forwardRef } from "react";
import { useFrame } from "@react-three/fiber";
import { useRaycastVehicle } from "@react-three/cannon";
import { useControls } from "./useControls";
import Body from "./Body";
import Wheel from "./Wheel";

const Vehicle = forwardRef(
  (
    {
      radius = 0.75,
      width = 1.4,
      height = 0.3,
      front = 1.05,
      back = -1.25,
      steer = 1,
      force = 3000,
      maxBrake = 1e5,
      ...props
    },
    ref
  ) => {
    const chassis = useRef();
    const wheel1 = useRef();
    const wheel2 = useRef();
    const wheel3 = useRef();
    const wheel4 = useRef();
    const controls = useControls();

    const wheelInfo = {
      radius,
      directionLocal: [0, -1, 0],
      suspensionStiffness: 30,
      suspensionRestLength: 0.3,
      maxSuspensionForce: 1e4,
      maxSuspensionTravel: 0.3,
      dampingRelaxation: 10,
      dampingCompression: 4.4,
      axleLocal: [-1, 0, 0],
      chassisConnectionPointLocal: [1, 0, 1],
      useCustomSlidingRotationalSpeed: true,
      customSlidingRotationalSpeed: -30,
      frictionSlip: 2,
    };

    const wheelInfo1 = {
      ...wheelInfo,
      isFrontWheel: true,
      chassisConnectionPointLocal: [-width / 2, height, front],
    };
    const wheelInfo2 = {
      ...wheelInfo,
      isFrontWheel: true,
      chassisConnectionPointLocal: [width / 2, height, front],
    };
    const wheelInfo3 = {
      ...wheelInfo,
      isFrontWheel: false,
      chassisConnectionPointLocal: [-width / 2, height, back],
    };
    const wheelInfo4 = {
      ...wheelInfo,
      isFrontWheel: false,
      chassisConnectionPointLocal: [width / 2, height, back],
    };

    const [vehicle, api] = useRaycastVehicle(() => ({
      chassisBody: chassis,
      wheels: [wheel1, wheel2, wheel3, wheel4],
      wheelInfos: [wheelInfo1, wheelInfo2, wheelInfo3, wheelInfo4],
      indexForwardAxis: 2,
      indexRightAxis: 0,
      indexUpAxis: 1,
    }));

    const p = ref;
    useEffect(() => {
      const unsubscribe = chassis.current.api.position.subscribe(
        (position) => (p.current = position)
      );
      return unsubscribe;
    }, []);

    useEffect(() => {
      const enterKey = (event) => {
        if (event.key === "Enter") {
          const x = Math.round(p.current[0]);
          const y = Math.round(p.current[1]);
          const z = Math.round(p.current[2]);
          if (x === -0 && y === 1 && z === 20) {
            console.log("hi");
          }
        }
      };
      document.addEventListener("keydown", enterKey);
      return () => {
        window.removeEventListener("keydown", enterKey);
      };
    }, []);

    useFrame(() => {
      const { forward, backward, left, right, brake, reset } = controls.current;
      for (let e = 2; e < 4; e++)
        api.applyEngineForce(
          forward || backward ? force * (forward && !backward ? -1 : 1) : 0,
          2
        );
      for (let s = 0; s < 2; s++)
        api.setSteeringValue(
          left || right ? steer * (left && !right ? 1 : -1) : 0,
          s
        );
      for (let b = 2; b < 4; b++) api.setBrake(brake ? maxBrake : 0, b);
      if (reset) {
        resetPostion();
      }
    });
    function resetPostion() {
      chassis.current.api.position.set(0, 1, 20);
      chassis.current.api.velocity.set(0, 0, 0);
      chassis.current.api.angularVelocity.set(0, 0.5, 0);
      chassis.current.api.rotation.set(0, 10, 0);
    }

    return (
      <group ref={vehicle} position={[0, -0.4, 0]}>
        <Body
          ref={chassis}
          rotation={props.rotation}
          position={props.position}
          angularVelocity={props.angularVelocity}
        />
        <Wheel ref={wheel1} radius={radius} leftSide />
        <Wheel ref={wheel2} radius={radius} />
        <Wheel ref={wheel3} radius={radius} leftSide />
        <Wheel ref={wheel4} radius={radius} />
      </group>
    );
  }
);

export default Vehicle;
