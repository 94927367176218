import styles from "./Jelly.module.css";

export default function JellyFont({ title, size, color }) {
  const words = title.split(" ");
  return (
    <>
      <div style={{ fontSize: size }}>
        {words.map((word) => (
          <>
            {[...word].map((char, index) => (
              <span
                key={index}
                className={styles.span}
                style={{ color: color }}
              >
                {char}
              </span>
            ))}
            <span>&nbsp;</span>
          </>
        ))}
      </div>
    </>
  );
}
