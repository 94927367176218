import { CHANGE_THEME_MODE } from "../constants/themeConstants";
import { lightTheme } from "../../components/theme/muiTheme";

const reducer = (state = lightTheme, action) => {
  switch (action.type) {
    case CHANGE_THEME_MODE:
      return action.payload;

    default:
      return state;
  }
};

export default reducer;
