import React from "react";
import Grid from "@mui/material/Grid";
import JellyFont from "../../components/jellyFont/JellyFont";
import Box from "@mui/material/Box";
import Ball from "../../components/models/confetti/Ball";
import { useSelector } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";

export default function Home() {
  const theme = useSelector((state) => state.theme);

  return (
    <Scrollbars
      style={{
        backgroundColor: theme.home.backgroundColor,
        overflowX: "hidden",
        height: "84vh",
        width: "90%",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "6vh",
        borderRadius: "10px",
        boxShadow:
          "0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%)",
      }}
    >
      <Box>
        <Grid container component="main" sx={{ height: "84vh" }}>
          <Grid item xs={12} sm={12} md={6} style={{ marginTop: "40vh" }}>
            <p
              style={{
                marginLeft: "6vw",
                color: theme.home.descriptionFontColor,
                marginTop: "-3vh",
                fontFamily: "Ephesis",
              }}
            >
              {"<body>"}
            </p>
            <div
              class="hover:p-6"
              style={{
                display: "inline-block",
                position: "relative",
                marginLeft: "8vw",
                transition: "all 1s ease",
              }}
            >
              <JellyFont
                title={"Hi,"}
                size={"4rem"}
                color={theme.home.titleFontColor}
              />
            </div>

            <br />
            <div
              class="hover:p-6"
              style={{
                display: "inline-block",
                position: "relative",
                marginLeft: "9vw",
                marginTop: "1vh",
                marginBottom: "1vh",
                transition: "all 1s ease",
              }}
            >
              <JellyFont
                title={"I'm Longlong"}
                size={"3rem"}
                color={theme.home.titleFontColor}
              />
            </div>
            <br />
            <div
              class="hover:p-6"
              style={{
                display: "inline-block",
                position: "relative",
                marginLeft: "8vw",
                transition: "all 1s ease",
              }}
            >
              <JellyFont
                title={"Full Stack Engineer"}
                size={"2.75rem"}
                color={theme.home.titleFontColor}
              />
            </div>
            <div
              style={{
                margin: "5px",
                marginLeft: "10vw",
                color: "#F5EBE7",
                minWidth: "60vw",
                transition: "all 1s ease",
                "&:hover,&:focus": {
                  padding: "5vw",
                },
              }}
            >
              <JellyFont
                title={"We all start from hello world, don't we?"}
                size={"0.9rem"}
                color={theme.home.descriptionFontColor}
              />
            </div>
            <br />
            <p
              style={{
                marginLeft: "6vw",
                color: theme.home.descriptionFontColor,
                marginTop: "-3vh",
                fontFamily: "Ephesis",
              }}
            >
              {"</body>"}
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6} style={{ height: "84vh" }}>
            <Ball />
          </Grid>
        </Grid>
      </Box>
    </Scrollbars>
  );
}
