import React, { Fragment, useRef } from "react";
import * as THREE from "three";
import { useMemo } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { OrbitControls, Sky, Environment } from "@react-three/drei";
import CameraControls from "camera-controls";
import Grid from "@mui/material/Grid";
import SkillText from "./SkillText";
import { skills } from "./skills";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { dialogActions } from "../../redux/actions";
import CustomDialog from "../../components/dialog/CustomDialog";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";

CameraControls.install({ THREE });

function Controls({ group }) {
  const camera = useThree((state) => state.camera);
  const gl = useThree((state) => state.gl);
  useMemo(
    () => new CameraControls(camera, gl.domElement),
    [camera, gl.domElement]
  );
  return useFrame((state) => {
    group.current.rotation.y = Math.sin(state.clock.elapsedTime) * 0.05;
  });
}

const Skill = () => {
  const { openDialog } = bindActionCreators(dialogActions, useDispatch());
  const group = useRef();

  const theme = useSelector((state) => state.theme);

  return (
    <>
      <Box
        sx={{
          bgcolor: theme.skill.backgroundColor,
          overflowX: "hidden",
          height: "100%",
          width: "90%",
          marginTop: "6vh",
          marginLeft: "auto",
          marginRight: "auto",
          borderRadius: "10px",
          boxShadow: 10,
        }}
      >
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={12}>
            <Fragment>
              <Canvas
                camera={{ fov: 150, position: [0, 0, 35] }}
                style={{
                  height: "84vh",
                  position: "relative",
                }}
              >
                <ambientLight intensity={2} />
                <pointLight position={[40, 40, 40]} />
                <group ref={group}>
                  {skills.map((skill) => (
                    <SkillText
                      zoomToView={(focusRef) => openDialog(focusRef)}
                      skill={skill}
                    />
                  ))}
                </group>
                <Sky />
                <Environment preset="city" />
                <Controls group={group} />
                <OrbitControls />
              </Canvas>
            </Fragment>
          </Grid>
        </Grid>
        <CustomDialog />
      </Box>
    </>
  );
};

export default Skill;
