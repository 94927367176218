import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeIcon from "@mui/icons-material/Home";
import CodeIcon from "@mui/icons-material/Code";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import NavigationIcon from "@mui/icons-material/Navigation";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { themeActions, navActions } from "../../redux/actions";
import { lightTheme } from "../theme/muiTheme";
import { darkTheme } from "../theme/muiTheme";
import { Link } from "react-router-dom";
import TerminalIcon from "@mui/icons-material/Terminal";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const Nav = () => {
  const [open, setOpen] = React.useState(true);
  const [transition, setTransition] = React.useState(undefined);

  const handleClick = (Transition) => () => {
    setTransition(() => Transition);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const state = useSelector((state) => state.nav);
  const theme = useSelector((state) => state.theme);

  const { changeValue, showButtonNav, showTerminalNav } = bindActionCreators(
    navActions,
    useDispatch()
  );

  const handleOnClick = (path) => {
    console.log(path);
    if (path === "terminal") {
      showButtonNav(false);
      showTerminalNav(true);
    } else {
      changeValue(path);
    }
  };

  const { changeTheme } = bindActionCreators(themeActions, useDispatch());
  const [mode, setMode] = React.useState("light");
  const toggleColorMode = () => {
    console.log(lightTheme);
    var el = document.getElementById("body");
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
    if (mode === "light") {
      el.style.transition = "0.5s";
      el.style.backgroundColor = darkTheme.body;
      changeTheme(darkTheme);
    } else {
      el.style.transition = "0.5s";
      el.style.backgroundColor = lightTheme.body;
      changeTheme(lightTheme);
    }
  };
  // style={{
  //   backgroundImage: `url(${image})`,
  // }}
  return (
    <>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
        style={{
          zIndex: 1000,
        }}
      >
        <Snackbar
          open={open}
          onClose={handleClose}
          TransitionComponent={transition}
          message="Still Working On It"
          key={transition ? transition.name : ""}
        />
        <BottomNavigation
          value={state.value}
          style={{ backgroundColor: theme.nav.backgroundColor }}
        >
          <BottomNavigationAction
            component={Link}
            to="/"
            label="Home"
            value="/home"
            onClick={() => handleOnClick("/home")}
            icon={<HomeIcon />}
          />

          <BottomNavigationAction
            component={Link}
            to="/skill"
            label="Skill"
            value="/skill"
            onClick={() => handleOnClick("/skill")}
            icon={<CodeIcon />}
          />
          <BottomNavigationAction
            component={Link}
            to="/experience"
            label="Experience"
            value="/experience"
            onClick={() => handleOnClick("/experience")}
            icon={<WorkOutlineIcon />}
          />
        </BottomNavigation>
        <SpeedDial
          ariaLabel="SpeedDial"
          sx={{
            position: "absolute",
            bottom: 1,
            right: "1.5vw",
          }}
          icon={<SpeedDialIcon />}
        >
          <SpeedDialAction
            icon={<TerminalIcon />}
            tooltipTitle="Terminal Navigation"
            onClick={() => handleOnClick("terminal")}
          />
          <SpeedDialAction
            icon={mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
            tooltipTitle="Change Theme"
            onClick={() => toggleColorMode()}
          />
        </SpeedDial>
      </Paper>
    </>
  );
};

export default Nav;
