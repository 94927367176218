import { BOTTOMM_NAVIGATION_VALUE } from "../constants/navConstants";
import { IP_ADDRESS } from "../constants/navConstants";
import { SHOW_BOTTOMM_NAVIGATION } from "../constants/navConstants";
import { SHOW_TERMINAL_NAVIGATION } from "../constants/navConstants";
const reducer = (
  state = {
    value: "/home",
    ipAddress: "",
    showButtonNav: true,
    showTerminalNav: false,
  },
  action
) => {
  switch (action.type) {
    case BOTTOMM_NAVIGATION_VALUE:
      return {
        value: action.payload,
        ipAddress: state.ipAddress,
        showButtonNav: state.showButtonNav,
        showTerminalNav: state.showTerminalNav,
      };
    case IP_ADDRESS:
      return {
        value: "/home",
        ipAddress: action.payload,
        showButtonNav: state.showButtonNav,
        showTerminalNav: state.showTerminalNav,
      };
    case SHOW_BOTTOMM_NAVIGATION:
      return {
        value: "/home",
        ipAddress: state.ipAddress,
        showButtonNav: action.payload,
        showTerminalNav: state.showTerminalNav,
      };
    case SHOW_TERMINAL_NAVIGATION:
      return {
        value: "/home",
        ipAddress: state.ipAddress,
        showButtonNav: state.showButtonNav,
        showTerminalNav: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
