import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { dialogActions } from "../../redux/actions";
import Zoom from "@mui/material/Zoom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} style={{ transitionDelay: "300ms" }} />;
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function AlertDialogSlide() {
  const state = useSelector((state) => state.dialog);
  const { closeDialog } = bindActionCreators(dialogActions, useDispatch());

  const theme = useSelector((state) => state.theme);

  return (
    <div style={{ zIndex: 10000 }}>
      <Dialog
        open={state.value}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeDialog}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth="lg"
        style={{
          backdropFilter: "blur(8px)",
          transition: "all 500ms ease",
        }}
      >
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            transitionDelay: "300ms",
            background: theme.customDialog.backgroundColor,
          }}
        >
          <Grid justifyItems="center" style={{ transitionDelay: "300ms" }}>
            <Grid
              item
              xs={12}
              style={{ background: theme.customDialog.backgroundColor }}
            >
              <Item style={{ background: theme.customDialog.backgroundColor }}>
                <DialogTitle>
                  <span style={{ color: theme.customDialog.fontColor }}>
                    {state.data.name}
                  </span>
                </DialogTitle>
              </Item>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Divider />
        <DialogContent
          style={{ background: theme.customDialog.backgroundColor }}
        >
          {state.data.description && (
            <ol class="list-disc">
              {state.data.description.map((line) => (
                <>
                  <li style={{ color: theme.customDialog.fontColor }}>
                    {line}
                  </li>
                  <br />
                </>
              ))}
            </ol>
          )}
          <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
