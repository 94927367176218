import * as React from "react";
import Drawer from "@mui/material/Drawer";
import Terminal from "../../pages/terminal/Terminal";
import Nav from "../nav/Nav";
import { useSelector } from "react-redux";

export default function Navigation() {
  const [state, setState] = React.useState(true);
  const nav = useSelector((state) => state.nav);

  const toggleDrawer = (status) => (event) => {
    setState(status);
  };

  function handleUserKeyPress(event) {
    if (event.key === "c" || event.key === "C") {
      setState(true);
    }
    if (event.key === "Escape") {
      setState(false);
    }
  }
  React.useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);

    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, []);

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor="bottom"
          open={state && nav.showTerminalNav}
          onClose={toggleDrawer(false)}
        >
          <Terminal />
        </Drawer>
      </React.Fragment>
      {nav.showButtonNav && <Nav />}
    </div>
  );
}
