import { createTheme } from "@mui/material/styles";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#A9DED4",
      contrastText: "#F2F2F2",
    },
  },
  nav: {
    backgroundColor: "#F2F2F2",
  },
  body: "#9cd8ce",
  terminal: {
    currentTheme: "light",
    successColor: "black",
    errorColor: "#D52956",
    normalColor: "#585859",
  },
  home: {
    backgroundColor: "#A9DED4",
    titleFontColor: "#A6A6A6",
    descriptionFontColor: "#585859",
  },
  skill: {
    backgroundColor: "#A9DED4",
  },
  experience: {
    backgroundColor: "#A9DED4",
    titleFontColor: "#585859",
    descriptionFontColor: "#585859",
  },
  timeLine: {
    right: {
      contentBackground: "#A6A6A6",
      contentFontColor: "#F2F2F2",
      contentArrowStyle: "7px solid  #A6A6A6",
      iconBackground: "#A6A6A6",
      iconColor: "#A6A6A6",
    },
    left: {
      contentBackground: "#F2F2F2",
      contentFontColor: "#585859",
      contentArrowStyle: "7px solid #F2F2F2",
      iconBackground: "#F2F2F2",
      iconColor: "#F2F2F2",
    },
  },
  customDialog: {
    backgroundColor: "#A9DED4",
    fontColor: "#A6A6A6",
  },
  contact: {
    backgroundColor: "#A9DED4",
    titleFontColor: "#A6A6A6",
    descriptionFontColor: "#585859",
  },
  components: {
    MuiSpeedDial: {
      styleOverrides: {
        fab: {
          backgroundColor: "#A9DED4",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#9cd8ce",
          color: "#4C5958",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#9cd8ce",
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1D1D1D",
      contrastText: "white",
    },
  },
  body: "#10403B",
  nav: {
    backgroundColor: "#8AA6A3",
  },
  terminal: {
    currentTheme: "dark",
    successColor: "white",
    errorColor: "#D52956",
    normalColor: "white",
  },
  home: {
    backgroundColor: "#127369",
    titleFontColor: "#4C5958",
    descriptionFontColor: "#BFBFBF",
  },
  skill: {
    backgroundColor: "#127369",
  },
  experience: {
    backgroundColor: "#127369",
    titleFontColor: "#BFBFBF",
    descriptionFontColor: "#4C5958",
  },
  timeLine: {
    right: {
      contentBackground: "#4C5958",
      contentFontColor: "#BFBFBF",
      contentArrowStyle: "7px solid  #4C5958",
      iconBackground: "#4C5958",
      iconColor: "#4C5958",
    },
    left: {
      contentBackground: "#8AA6A3",
      contentFontColor: "#4C5958",
      contentArrowStyle: "7px solid  #8AA6A3",
      iconBackground: "#8AA6A3",
      iconColor: "#8AA6A3",
    },
  },
  customDialog: {
    backgroundColor: "#127369",
    fontColor: "#BFBFBF",
  },
  contact: {
    backgroundColor: "#127369",
    titleFontColor: "#4C5958",
    descriptionFontColor: "#BFBFBF",
  },
  components: {
    MuiSpeedDial: {
      styleOverrides: {
        fab: {
          backgroundColor: "#10403B",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#4C5958",
          color: "#8AA6A3",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#10403B",
        },
      },
    },
  },
});
