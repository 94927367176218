import { forwardRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useBox } from "@react-three/cannon";
useGLTF.preload("/car.glb");
const Beetle = forwardRef(
  ({ args = [1.7, 1, 4], mass = 500, ...props }, ref) => {
    const { nodes, materials } = useGLTF("/car.glb");
    const [, api] = useBox(
      () => ({
        mass,
        args,
        allowSleep: false,
        onCollide: (e) => {
          // console.log("bonk", e.body.userData);
        },
        ...props,
      }),
      ref
    );
    return (
      <mesh ref={ref} api={api}>
        <group position={[0, 0, 0]}>
          <mesh material={materials.MAIN} geometry={nodes.car_body.geometry} />
          <mesh
            position={nodes.door_l.position}
            material={materials.MAIN}
            geometry={nodes.door_l.geometry}
          />
          <mesh
            position={nodes.door_r.position}
            material={materials.MAIN}
            geometry={nodes.door_r.geometry}
          />
          <mesh
            position={nodes.free_number.position}
            material={materials.MAIN}
            geometry={nodes.free_number.geometry}
          />
          <mesh
            position={nodes.windows.position}
            material={materials.WINDOW}
            geometry={nodes.windows.geometry}
          />
        </group>
      </mesh>
    );
  }
);

export default Beetle;
