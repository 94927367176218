import { combineReducers } from "redux";
import accountReducer from "./reducers/accountReducer";
import navReducer from "./reducers/navReducer";
import themeReducer from "./reducers/themeReducer";
import dialogReducer from "./reducers/dialogReducer";

const reducers = combineReducers({
  account: accountReducer,
  nav: navReducer,
  theme: themeReducer,
  dialog: dialogReducer,
});

export default reducers;
