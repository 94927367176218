import { OPEN_DIALOG, CLOSE_DIALOG } from "../constants/dialogConstants";

export const openDialog = (payload) => {
  return (dispatch) => {
    dispatch({ type: OPEN_DIALOG, payload });
  };
};

export const closeDialog = () => {
  return (dispatch) => {
    dispatch({ type: CLOSE_DIALOG });
  };
};
