import React, { useMemo, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { Text3D } from "@react-three/drei";
import boldUrl from "../../assets/three/bold.blob";

const SkillText = ({
  zoomToView,
  skill,
  vAlign = "center",
  hAlign = "center",
  size = 1.5,
  color = "#000000",
  ...props
}) => {
  const config = useMemo(
    () => ({
      size: 40,
      height: 30,
      curveSegments: 32,
      bevelEnabled: true,
      bevelThickness: 6,
      bevelSize: 2.5,
      bevelOffset: 0,
      bevelSegments: 8,
    }),
    []
  );
  const mesh = useRef();

  useFrame((state) => {
    mesh.current.position.y = Math.sin(5000 + state.clock.elapsedTime) * 7;
  });

  return (
    <group
      {...props}
      position={skill.position}
      scale={[0.1 * size, 0.1 * size, 0.03]}
    >
      <Text3D
        ref={mesh}
        key={skill.name}
        font={boldUrl}
        onClick={(e) => {
          zoomToView(skill.name);
        }}
        {...config}
      >
        {skill.name}
        <meshNormalMaterial />
      </Text3D>
    </group>
  );
};

export default SkillText;
